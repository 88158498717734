import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
// Components
import Layout from '../components/layout'
import AnimatedArrow from '../components/animated-arrow'
import SEO from '../components/seo'
import Button from '../components/button'

// Styles
import '../styles/pages/bar-desktop.scss'
import TitlePattern from '../images/title-pattern.inline.svg'
import Pattern from '../images/tuya-pattern-menu.inline.svg'
import useWindowSize from '../hooks/useWindowSize'
import IconGlassOrange from '../images/icon-glass-orange.inline.svg'
import IconGlassGin from '../images/icon-glass-gin.inline.svg'
import IconGlassBasic from '../images/icon-glass-basic.inline.svg'
import IconGlassSparkling from '../images/icon-glass-sparkling.inline.svg'
import IconSvg from '../components/icon-svg'
import Slider, { CurrentSlide, MobileNav, NextSlide } from '../components/slider'

const Section = ({ category, className, pattern }) => {
  return (
    <section className={className}>
      {pattern && (
        <TitlePattern className="absolute hidden md:block top-2 md:-left-36 lg:-left-28 2xl:-left-12" />
      )}
      <div className="px-6 md:p-0 md:max-w-lg lg:max-w-screen-lg md:mx-auto">
        <h2 className="text-tuya-slate mb-8 text-3xl md:text-4xl leading-relaxed relative">
          {category.title}
          <div className="absolute left-0 top-full border-b border-tuya-yellow w-40" />
        </h2>
        <ul className="grid gap-y-5">
          {category.list.map((menuItem, i) => (
            <li key={i}>
              <h3 className="text-tuya-dark text-lg mb-1">{menuItem.title}</h3>
              {menuItem.description && (
                <p className="text-tuya-description text-xs italic">{menuItem.description}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
const HeroSection = ({ image, title }) => {
  return (
    <section className="bg-black hero-section relative">
      <div className="img-wrapper w-full h-full absolute top-0 left-0 z-1">
        <img
          className="w-full h-full object-cover opacity-50"
          src={image.sizes.src}
          srcSet={image.sizes.srcSet}
          alt="{title}"
        />
      </div>
      <div className="z-10 relative px-8 md:px-0 pb-11 md:max-w-2xl  lg:max-w-screen-lg md:mx-auto h-full flex items-end">
        <div>
          <Pattern className="text-tuya-yellow mb-3" />
          <h1 className="text-white">{title}</h1>
        </div>
      </div>
    </section>
  )
}

const MenuContainer = ({ model, className, children }) => {
  return (
    <div className={`px-4 ${className}`}>
      <div className="border-b border-tuya-yellow pb-1 relative w-full md:w-max text-center md:text-left">
        <h2 className="text-2xl md:text-4xl font-normal text-tuya-slate">{model.title}</h2>
        <p className="text-sm md:text-base font-light text-tuya-dark mt-2">{model.description}</p>
        {children}
      </div>
      <div>
        {model.list.map((item, i) => (
          <div
            className={`flex flex-row justify-between mt-8 ${i === 0 ? '2xl:mt-10' : '2xl:mt-8'}`}
            key={i}
          >
            <div>
              <div className="text-tuya-slate font-medium capitalize">{item.title}</div>
              <span className="text-tuya-dark text-sm font-light">{item.description}</span>
            </div>
            <div className="text-tuya-slate">{item.price}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const MenuHeadTitle = ({ model, forceWFull = false, children }) => {
  return (
    <>
      <div
        className={
          (forceWFull ? '2xl:w-full' : '2xl:w-max') +
          ' w-full text-center md:text-left border-b border-tuya-yellow pb-1 relative'
        }
      >
        <h2 className="text-2xl md:text-4xl font-normal text-tuya-slate">{model.title}</h2>
        {model.description && (
          <p className="text-sm md:text-base font-light text-tuya-dark mt-2">{model.description}</p>
        )}
        {children}
      </div>
    </>
  )
}
const MenuProdList = ({ prods, allDark = true, prodContainerClass = '' }) => {
  return (
    <div className={`mt-8 ${prodContainerClass}`}>
      {prods.map((prod, i) => (
        <div className="leading-none flex flex-row justify-between mt-3 md:mt-6 2xl:mt-4" key={i}>
          <div
            className={
              (prod.description && !allDark ? 'text-tuya-slate' : 'text-tuya-dark') +
              ' uppercase font-normal text-sm md:text-base'
            }
          >
            {prod.title}
            {prod.description && (
              <div className="text-tuya-dark mt-1 text-sm font-light lowercase pr-8 whitespace-pre-line">
                {prod.description}
              </div>
            )}
          </div>
          <div className="text-tuya-slate text-sm md:text-base">{prod.price}</div>
        </div>
      ))}
    </div>
  )
}

const BarComponent = ({
  cocktailsSignature,
  classicCocktails,
  cocktailsSlider,
  classicCocktailsSlider,
  drinks,
  headerImage,
  pageTitle,
  viewMode,
  isVertical,
}) => {

  return (
    <>
      <HeroSection image={headerImage} title={pageTitle} />
      <section className="cocktails-signature relative mt-10 md:mt-20 md:grid 2xl:mt-16">
        <TitlePattern className="absolute hidden 2xl:block 2xl:-left-12" />
        <div></div>
        <div className="px-4 md:px-0">
          <MenuHeadTitle model={cocktailsSignature} />
          <div>
            {cocktailsSignature.list.map((item, i) => (
              <div
                className={`relative flex flex-row justify-between mt-9 md:mr-3 ${
                  i === 0 ? '2xl:mt-10' : '2xl:mt-9'
                }`}
                key={i}
              >
                {'' !== item.icon && (
                  <div className="absolute hidden 2xl:block 2xl:-left-32 2xl:w-24">
                    <IconSvg iconName={item.icon} alt={item.title} />
                  </div>
                )}

                <div className="mr-4">
                  <div className="text-tuya-slate font-medium">{item.title}</div>
                  <p className="text-tuya-dark text-sm font-light">{item.description}</p>
                </div>
                <div className="text-tuya-slate">{item.price}</div>
              </div>
            ))}
          </div>
        </div>
        <Slider carousel={cocktailsSlider} reversed>
          <NextSlide
            sliderId="cocktails"
            noGrayscale
            className="col-start-6 mt-20 hidden md:block"
            hFull=""
          />
          <CurrentSlide
            sliderId="cocktails"
            className="row-start-1 col-start-4 mt-20 hidden md:block"
            hFull=""
          />
          <MobileNav
            sliderId="cocktails"
            mt={4}
            className="items-start row-start-2 col-start-4 hidden md:flex"
          />
        </Slider>
      </section>

      <section className="classic-cocktails relative mt-12 md:mt-24 md:grid px-4 md:px-0">
        <Slider carousel={classicCocktailsSlider}>
          <NextSlide
            sliderId="first"
            noGrayscale
            className="row-start-1 col-start-1 hidden md:block"
            hFull=""
          />
          <CurrentSlide sliderId="first" className="row-start-1 col-start-3" hFull="" />
          <MobileNav
            sliderId="first"
            mt={4}
            className="items-start row-start-2 col-start-3 md:row-start-3"
          />
        </Slider>
        <div></div>
        <div></div>
        <div className="mt-24 md:mt-0">
          <MenuHeadTitle model={classicCocktails}>
            <div className="inset-x-0 absolute -top-24 md:w-full 2xl:-left-24 2xl:top-0">
              <IconGlassOrange className="mx-auto 2xl:mx-0" />
            </div>
          </MenuHeadTitle>
          <div>
            {classicCocktails.list.map((item, i) => (
              <div
                className={`relative flex flex-row justify-between mt-9 ${
                  i === 0 ? '2xl:mt-10' : '2xl:mt-9'
                }`}
                key={i}
              >
                <div className="mr-4">
                  <div className="text-tuya-slate font-medium">{item.title}</div>
                  <p className="text-tuya-dark text-sm font-light">{item.description}</p>
                </div>
                <div className="text-tuya-slate">{item.price}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-grey-300 mt-12 md:mt-20 pt-10 2xl:pb-32 pb-16 2xl:mt-24 2xl:pt-16">
        <div className="lg:container mx-auto md:grid md:grid-cols-12">
          <MenuContainer
            model={drinks.ginAndTonic}
            className={'mt-20 md:mt-0 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4'}
          >
            <div className="inset-x-0 absolute -top-28 w-full md:w-max md:-left-20 md:top-0 2xl:-top-4">
              <IconGlassGin className="mx-auto 2xl:mx-0" />
            </div>
          </MenuContainer>

          <MenuContainer
            model={drinks.sparklingCocktails}
            className={
              'mt-36 md:mt-20 md:col-start-3 md:col-span-8 2xl:col-start-7 2xl:col-span-4 2xl:pl-10 2xl:mt-0'
            }
          >
            <div className="inset-x-0 absolute -top-28 w-full md:w-max md:-left-20 md:top-0">
              <IconGlassSparkling className="mx-auto 2xl:mx-0" />
            </div>
          </MenuContainer>
        </div>
      </section>

      <section className="mt-12 md:mt-20">
        <div className="lg:container mx-auto grid grid-cols-12">
          {viewMode === VIEW_DESKTOP ? (
            <>
              <MenuContainer model={drinks.refreshers} className={'2xl:col-start-2 2xl:col-span-4'}>
                <IconGlassBasic className="-left-20 absolute top-0" />
              </MenuContainer>
              <div className="2xl:col-start-7 2xl:col-span-4 px-4 pl-10">
                <img src={drinks.refreshers.refreshersImage.sizes.src} />
              </div>
            </>
          ) : (
            <>
              <div className="col-start-1 col-span-12 px-4 md:pl-3 md:pl-0 md:col-start-1 md:col-span-5">
                <img src={drinks.refreshers.refreshersImage.sizes.src} />
              </div>
              <MenuContainer
                model={drinks.refreshers}
                className={
                  'col-start-1 col-span-12 mt-28 md:col-start-7 md:col-span-5 md:pl-0 md:pr-0'
                }
              >
                <div className="md:inset-x-0 absolute -top-24 w-full">
                  <IconGlassBasic className="mx-auto" />
                </div>
              </MenuContainer>
            </>
          )}
        </div>
      </section>

      {drinks.whiskeys && (
        <section className="mt-12 md:mt-20 px-4 md:px-0 2xl:mt-10">
          <div className="lg:container mx-auto grid grid-cols-12">
            <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4 2xl:pl-3 2xl:pr-5">
              <MenuHeadTitle model={drinks.whiskeys} />
              {drinks.whiskeys.list.map((whiskey, j) => {
                if (j < 2) {
                  return (
                    <div key={j} className="break-inside mt-8">
                      <span className="text-tuya-slate font-medium capitalize flex mb-2">
                        {whiskey.category}
                      </span>
                      <MenuProdList prods={whiskey.list} prodContainerClass={'2xl:mt-4 2xl:mt-6'} />
                    </div>
                  )
                }
              })}
            </div>
            <div className="mt-12 col-start-1 col-span-12 md:col-start-3 md:col-span-8 md:mt-0 2xl:pl-32 2xl:col-start-6 2xl:col-span-5 2xl:mt-0 2xl:pr-5 2xl:pt-16">
              {drinks.whiskeys.list.map((whiskey, j) => {
                if (j >= 2) {
                  return (
                    <div key={j} className="break-inside mt-8">
                      <span className="text-tuya-slate font-medium capitalize flex mb-2">
                        {whiskey.category}
                      </span>
                      <MenuProdList prods={whiskey.list} prodContainerClass={'2xl:mt-4 2xl:mt-6'} />
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </section>
      )}
      <section className="mt-12 md:mt-20 px-4 md:px-0 2xl:mt-10">
        <div className="lg:container mx-auto grid grid-cols-12">
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4 2xl:pl-3 2xl:pr-5">
            <MenuHeadTitle model={drinks.rumAndCachaca} />
            <MenuProdList prods={drinks.rumAndCachaca.list} />
          </div>
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 md:mt-20 mt-12 2xl:pl-32 2xl:col-start-6 2xl:col-span-5 2xl:mt-0 2xl:pr-5">
            <MenuHeadTitle model={drinks.cognacAndBrandy} />
            <MenuProdList prods={drinks.cognacAndBrandy.list} />
          </div>
        </div>
      </section>

      <section className="mt-12 md:mt-20 px-4 md:px-0 2xl:mt-10">
        <div className="lg:container mx-auto grid grid-cols-12">
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4 2xl:pl-3 2xl:pr-5">
            <MenuHeadTitle model={drinks.tequilaAndMezcal} />
            <MenuProdList prods={drinks.tequilaAndMezcal.list} />
          </div>
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 md:mt-20 mt-12 2xl:pl-32 2xl:col-start-6 2xl:col-span-5 2xl:mt-0 2xl:pr-5">
            <MenuHeadTitle model={drinks.vodka} />
            <MenuProdList prods={drinks.vodka.list} />
          </div>
        </div>
      </section>

      <section className="mt-12 md:mt-20 px-4 md:px-0 2xl:mt-10">
        <div className="lg:container mx-auto grid grid-cols-12">
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4 2xl:pl-3 2xl:pr-5">
            <MenuHeadTitle model={drinks.gin} />
            <MenuProdList
              allDark={false}
              prods={drinks.gin.list.filter((prod, i) => {
                if (i < 10) {
                  return prod
                }
              })}
            />
          </div>
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:pl-32 2xl:col-start-6 2xl:col-span-5 2xl:mt-20 2xl:pr-5">
            <MenuProdList
              allDark={false}
              prods={drinks.gin.list.filter((prod, i) => {
                if (i >= 10) {
                  return prod
                }
              })}
            />
          </div>
        </div>
      </section>

      <section className="mt-12 md:mt-20 px-4 md:px-0 2xl:mt-10">
        <div className="lg:container mx-auto grid grid-cols-12">
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4 2xl:pl-3 2xl:pr-5">
            <MenuHeadTitle model={drinks.digestif} />
            <MenuProdList prods={drinks.digestif.list} />
          </div>
          <div className="col-start-1 col-span-12 mt-12 md:col-start-3 md:col-span-8 md:mt-20 2xl:pl-32 2xl:pr-5 2xl:col-start-6 2xl:col-span-5 2xl:mt-0">
            <MenuHeadTitle model={drinks.aperitif} />
            <MenuProdList prods={drinks.aperitif.list} />
            <div className="mt-20 2xl:mt-10">
              <MenuHeadTitle model={drinks.beer} />
              <MenuProdList prods={drinks.beer.list} />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-12 md:mt-20 px-4 md:px-0 2xl:mt-10">
        <div className="lg:container mx-auto grid grid-cols-12">
          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 2xl:col-start-2 2xl:col-span-4 2xl:pl-3 2xl:pr-5">
            <MenuHeadTitle model={drinks.softDrinks} />
            <MenuProdList allDark={true} prods={drinks.softDrinks.list} />
            <div className="mt-12 md:mt-20 2xl:mt-10">
              <MenuHeadTitle model={drinks.juices} />
              <MenuProdList prods={drinks.juices.list} />
            </div>
            <div className="mt-12 md:mt-20 2xl:mt-10">
              <MenuHeadTitle model={drinks.coffeeTime} forceWFull={true} />
              <MenuProdList prods={drinks.coffeeTime.list} />
            </div>
          </div>

          <div className="col-start-1 col-span-12 md:col-start-3 md:col-span-8 mt-12 md:mt-20 2xl:mt-0 2xl:pl-32 2xl:col-start-6 2xl:col-span-5 2xl:pr-5">
            <MenuHeadTitle model={drinks.teaTime} />
            <MenuProdList allDark={false} prods={drinks.teaTime.list} />
            <div className="mt-12 md:mt-20 2xl:mt-10">
              <MenuHeadTitle model={drinks.mineralWater} />
              <MenuProdList prods={drinks.mineralWater.list} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="lg:container mx-auto grid grid-cols-12 px-4 md:px-0">
          <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-7 mt-20 2xl:col-start-2 2xl:col-span-4">
            <div className="bg-gray-200 p-7 2xl:px-16 md:relative lg:static">
              <TitlePattern className="mb-6" />
              <p className="font-light text-lg mb-6" style={{ color: '#646464' }}>
                Mediterranean ingredients and the simplicity of home cooking. Each dish showcases the true
                essence of mediterranean cuisine.
              </p>
              <Link style={{ fontSize: '1.75rem' }} className="text-tuya-slate-light" to="/food">
                Food menu
                <AnimatedArrow className="h-3 text-tuya-dark" />
              </Link>
            </div>
          </div>
          <div className="col-start-1 col-span-12 md:col-start-11 md:col-span-1 2xl:col-start-10 pt-12">
            <div className="h-full w-full reservation flex justify-center md:justify-end items-center lg:justify-center ">
              <Link to="/reservation">
                <Button isDark isVertical={isVertical}>
                  RESERVATION
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const VIEW_DESKTOP = 1
const VIEW_TABLET = 2
const VIEW_MOBILE = 3
const BarPage = ({ data, location }) => {
  const windowSize = useWindowSize()
  const [viewMode, setViewMode] = useState(VIEW_DESKTOP)
  const [forceVertical, setForceVertical] = useState(false)
  const {
    pageTitle,
    headerImage,
    cocktailsSlider,
    classicCocktailsSlider,
    refreshersImage,
  } = data.datoCmsBar
  const cocktailsSignature = JSON.parse(data.datoCmsBar.cocktailsSignature)
  const cocktails = JSON.parse(data.datoCmsBar.cocktails)
  const drinks = {
    whiskeys: JSON.parse(data.datoCmsBar.whiskeys),
    ginAndTonic: JSON.parse(data.datoCmsBar.ginAndTonic),
    sparklingCocktails: JSON.parse(data.datoCmsBar.sparklingCocktails),
    refreshers: { ...JSON.parse(data.datoCmsBar.refreshers), refreshersImage },
    rumAndCachaca: JSON.parse(data.datoCmsBar.rumAndCachaca),
    cognacAndBrandy: JSON.parse(data.datoCmsBar.cognacAndBrandy),
    tequilaAndMezcal: JSON.parse(data.datoCmsBar.tequilaAndMezcal),
    vodka: JSON.parse(data.datoCmsBar.vodka),
    gin: JSON.parse(data.datoCmsBar.gin),
    digestif: JSON.parse(data.datoCmsBar.digestif),
    aperitif: JSON.parse(data.datoCmsBar.aperitif),
    beer: JSON.parse(data.datoCmsBar.beer),
    softDrinks: JSON.parse(data.datoCmsBar.softDrinks),
    teaTime: JSON.parse(data.datoCmsBar.teaTime),
    juices: JSON.parse(data.datoCmsBar.juices),
    coffeeTime: JSON.parse(data.datoCmsBar.coffeeTime),
    mineralWater: JSON.parse(data.datoCmsBar.mineralWater),
  }
  const [pageNumber, setPageNumber] = useState(1)

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber)
  }
  useEffect(() => {
    if (windowSize.width >= 1440) {
      setViewMode(VIEW_DESKTOP)
    } else if (windowSize.width >= 1024) {
      setViewMode(VIEW_TABLET)
    } else {
      setViewMode(VIEW_MOBILE)
    }
  }, [windowSize])

  useEffect(() => {
    setForceVertical(viewMode === VIEW_MOBILE ? false : true)
  }, [viewMode])

  return (
    <Layout className="bar-page" pathname={location.pathname} alwaysBlack>
      <SEO title={pageTitle} />

      <>
        <BarComponent
          cocktailsSignature={cocktailsSignature}
          classicCocktails={cocktails}
          cocktailsSlider={cocktailsSlider}
          classicCocktailsSlider={classicCocktailsSlider}
          drinks={drinks}
          headerImage={headerImage}
          pageTitle={pageTitle}
          viewMode={viewMode}
          isVertical={forceVertical}
        />
      </>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsBar {
      pageTitle
      headerImage {
        size
        width
        height
        path
        format
        isImage
        notes
        author
        copyright
        filename
        basename
        exifInfo
        mimeType
        blurhash
        originalId
        url
        createdAt
        alt
        title
        customData
        sizes {
          src
          srcSet
        }
      }
      cocktails
      classicCocktailsSlider {
        id
        title
        image {
          height
          sizes {
            src
            srcSet
          }
        }
      }
      cocktailsSignature
      cocktailsSlider {
        id
        title
        image {
          height
          sizes {
            src
            srcSet
          }
        }
      }
      ginAndTonic
      sparklingCocktails
      refreshers
      refreshersImage {
        height
        sizes {
          src
          srcSet
        }
      }
      whiskeys
      rumAndCachaca
      cognacAndBrandy
      tequilaAndMezcal
      vodka
      gin
      digestif
      aperitif
      beer
      softDrinks
      teaTime
      juices
      coffeeTime
      mineralWater
      barMenu {
        url
      }
    }
  }
`

export default BarPage
